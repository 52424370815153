import axios from '@axios'

export default {
  getPayments(page, field) {
    return axios.post(`platform/payments/list?page=${page}`, field)
  },

  cancelPayment(id) {
    return axios.post(`platform/payments/cancel/${id}`)
  },

  getPayment(id) {
    return axios.get(`platform/payments/${id}`)
  },

  verifyStatus(id) {
    return axios.get(`platform/payments/verify/${id}/status`)
  },

  getStatByStatus(filter) {
    return axios.post('platform/payments/stats/status', filter)
  },

  getWithdrawByStatus(filter) {
    return axios.post('platform/payments/withdraw/status', filter)
  },

  getValueStatByStatus(filter) {
    return axios.post('platform/payments/value/status', filter)
  },

  getValueStatByReason(filter) {
    return axios.post('platform/payments/value/reason', filter)
  },

  getValueStatByValidity(filter) {
    return axios.post('platform/payments/value/validity', filter)
  },

  getGlobalStat() {
    return axios.get('platform/payments/stats/get')
  },
}
