import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/plugins/acl/routeProtection'
import store from '../store'
import userpages from '@/router/userpages'
import roadpages from '@/router/roadPages'
import configPages from '@/router/configPages'
import paiementpages from '@/router/paiementPages'
import documentpages from '@/router/documentPages'
import globalValPages from '@/router/globalValPages'
import recoverypages from '@/router/recoveryPages'
import reductCodePages from '@/router/reductCodePages'
import campaignPages from '@/router/campaignPages'
import searchPages from '@/router/searchPages'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
      title: 'Tableau de bord',
    },
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/members',
    name: 'members',
    component: () => import('@/views/members/Members.vue'),
    meta: {
      layout: 'content',
      title: 'Membres',
    },
  },

  {
    path: '/statuses_rec',
    name: 'statuses_rec',
    component: () => import('@/views/statusRec/StatusRec.vue'),
    meta: {
      layout: 'content',
      title: 'Statuts de recouvrement',
    },
  },

  {
    path: '/statuses',
    name: 'statuses',
    component: () => import('@/views/status/Status.vue'),
    meta: {
      layout: 'content',
      title: 'Statuts de paiement',
    },
  },
  {
    path: '/rec_types',
    name: 'rec_types',
    component: () => import('@/views/recType/RecType.vue'),
    meta: {
      layout: 'content',
      title: 'Type de recouvrement',
    },
  },
  {
    path: '/pay_reasons',
    name: 'pay_reasons',
    component: () => import('@/views/payReason/PayReason.vue'),
    meta: {
      layout: 'content',
      title: 'Motif de paiment',
    },
  },
  ...campaignPages,
  ...reductCodePages,
  ...userpages,
  ...roadpages,
  ...configPages,
  ...paiementpages,
  ...documentpages,
  ...globalValPages,
  ...recoverypages,
  ...searchPages,
  {
    path: '/admins',
    name: 'admins',
    component: () => import('@/views/members/Admins.vue'),
    meta: {
      layout: 'content',
      title: 'Administrateurs',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/role/Role.vue'),
    meta: {
      layout: 'content',
      title: 'Roles',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/notification/Notifications.vue'),
    meta: {
      layout: 'content',
      title: 'Notification',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      title: 'Authentification',
    },
  },

  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      title: 'Mot de passe oublé',
    },
  },

  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      title: 'Réinitialisation du mot de passe',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
    title: 'Erreur',
  },
  {
    path: '/error',
    component: () => import('@/views/Error404.vue'),
    name: 'error',
    meta: {
      layout: 'content',
      title: 'Erreur',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _, next) => {
  const isLogged = localStorage.getItem('accessToken')
  if (to.path === '/forgot-password' || to.path === '/reset-password') return next()

  //  return next({ name: 'error' })

  // eslint-disable-next-line no-mixed-operators
  if (to.path !== '/login' && !isLogged) {
    return next({ name: 'auth-login' })
  }

  return next()
})

const DEFAULT_TITLE = 'MBOA COVOITURAGE'
router.afterEach((to, from, failure) => {
  // document.title = to.meta.title || DEFAULT_TITLE;
  document.title = `Administration - ${to.meta.title}` || DEFAULT_TITLE
  if (failure) {
    console.log(`error route ${failure}`)
  }
})

export default router
