const searchpages = [
  {
    path: '/searches',
    name: 'searches',
    component: () => import('@/views/searches/Search.vue'),
    meta: {
      layout: 'content',
      title: 'Recherches',
    },
  },
  {
    path: '/searches/:id/show',
    name: 'showsearch',
    component: () => import('@/views/searches/SearchDetails.vue'),
    meta: {
      layout: 'content',
      title: "Détails d'un reserche",
    },
  },
]

export default searchpages
