import paymentService from '@/store/services/paymentService'
import opApiService from '@/store/services/opApiService'
import paymentTypeService from '@/store/services/paymentTypeService'
import store from '@/store'
import recoveryService from "@/store/services/recoveryService";

const state = {
  field: {
    base_amount: null,
    amount: null,
    svc_number: null,
    road_id: null,
    payment_type_id: null,
    reference: null,
    op_amount: null,
    destination_id: null,
    transaction_id: null,
    user_id: null,
    op_api_id: null,
    paginate: 10,
  },
  filterStat: {
    min_date: null,
    max_date: null,
  },
  filterWithdrawValueStat: {
    min_date: null,
    max_date: null,
    is_cashin: false,
  },
  filterDepositValueStat: {
    min_date: null,
    max_date: null,
    is_cashin: true,
  },
  filterWithdraw: {
    min_date: null,
    max_date: null,
  },
  filterReason: {
    min_date: null,
    max_date: null,
  },
  filterValidity: {
    min_date: null,
    max_date: null,
    refresh: 0,
  },
  base_amount_field: null,
  amount_field: null,
  user_id_field: null,
  op_api_id_field: null,
  paginate_field: null,
  svc_number_field: null,
  road_id_field: null,
  payment_type_id_field: null,
  reference_field: null,
  op_amount_field: null,
  destination_id_field: null,
  transaction_id_field: null,
  metaPayment: null,
  metaStats: {
    FAIL: null,
    PENDING: null,
    SUCCESSFUL: null,
    WARNING: null,
  },
  metaReasonStat: null,
  metaValidityStat: null,
  metaWithdrawStats: {
    FAIL: null,
    PENDING: null,
    SUCCESSFUL: null,
    WARNING: null,
  },
  metaWithdrawValueStats: {
    FAIL: null,
    PENDING: null,
    SUCCESSFUL: null,
    WARNING: null,
  },
  metaDepositValueStats: {
    FAIL: null,
    PENDING: null,
    SUCCESSFUL: null,
    WARNING: null,
  },
  catX: [],
  dataY: [],
  catWithdrawX: [],
  dataWithdrawY: [],
  catWithdrawValueX: [],
  dataWithdrawValueY: [],
  dataWithdrawValueColor: [],
  dataWithdrawValuePercent: [],
  catDepositValueX: [],
  dataDepositValueY: [],
  dataDepositValueColor: [],
  dataDepositValuePercent: [],
  catReasonValueX: [],
  dataReasonValueY: [],
  dataReasonValueColor: [],
  dataReasonValuePercent: [],
  catValidityValueX: [],
  dataValidityValueY: [],
  dataValidityValueColor: [],
  dataValidityValuePercent: [],
  metaOpApi: null,
  metaPaymentType: null,
  payment: {
    id: null,
    op_api_id: null,
    evaluate_id: null,
    payment_type_id: null,
    user_id: null,
    road_id: null,
    op_api: null,
    evaluate: null,
    payment_type: null,
    user: null,
    road: null,
    reference: null,
    base_amount: null,
  },
  metaGlobalStat: null,
  globalStatKeys: [],
  globalStatValue: [],
  refreshPayId: null,
  refreshPayCount: 0,
}

const getters = {
  opApis: state => (state.metaOpApi ? state.metaOpApi.data : []),
  paymentTypes: state => (state.metaPaymentType ? state.metaPaymentType.data : []),
  payments: state => (state.metaPayment ? state.metaPayment.data : []),
  current_page: state => (state.metaPayment ? state.metaPayment.current_page : 0),
  last_page: state => (state.metaPayment ? state.metaPayment.last_page : 0),
  per_page: state => (state.metaPayment ? state.metaPayment.per_page : 0),
  total: state => (state.metaPayment ? state.metaPayment.total : 0),
}

const actions = {

  getpaymentTypes({ commit }) {
    return paymentTypeService.getPaymentTypes(1, { paginate: 100 }).then(({ data }) => {
      commit('SET_META_PAYMENT_TYPE', data.result)

      /* const items = []
      data.result.forEach(elt => {
        items.push({ text: elt.operator.label, value: elt.id })
      })
      commit('SET_META_PAYMENT_TYPE', items) */

      return data
    })
  },


  getOpApis({ commit }) {
    return opApiService.getOpApis(1, { paginate: 100 }).then(({ data }) => {
      commit('SET_META_OP_API', data.result)

      return data
    })
  },

  getPayChartData({ commit, state }, filter) {
    return paymentService.getStatByStatus(filter).then(({ data }) => {
      commit('SET_FILTER', filter)
      commit('SET_META_STATS', data.result)

      return data
    })
  },

  getWithdrawChartData({ commit, state }, filter) {
    return paymentService.getWithdrawByStatus(filter).then(({ data }) => {
      commit('SET_FILTER_WITHDRAW', filter)
      commit('SET_META_WITHDRAW_STATS', data.result)

      return data
    })
  },

  getWithdrawValueChartData({ commit, state }, filter) {
    return paymentService.getValueStatByStatus(filter).then(({ data }) => {
      commit('SET_FILTER_WITHDRAW_VALUE', filter)
      commit('SET_META_WITHDRAW_VALUE_STATS', data.result)

      return data
    })
  },

  getDepositValueChartData({ commit, state }, filter) {
    return paymentService.getValueStatByStatus(filter).then(({ data }) => {
      commit('SET_FILTER_DEPOSIT_VALUE', filter)
      commit('SET_META_DEPOSIT_VALUE_STATS', data.result)

      return data
    })
  },

  getReasonValueChartData({ commit, state }, filter) {
    return paymentService.getValueStatByReason(filter).then(({ data }) => {
      commit('SET_FILTER_REASON_VALUE', filter)
      commit('SET_META_REASON_VALUE_STATS', data.result)

      return data
    })
  },

  getValidityValueChartData({ commit, state }, filter) {
    return paymentService.getValueStatByValidity(filter).then(({ data }) => {
      commit('SET_FILTER_VALIDITY_VALUE', filter)
      commit('SET_META_VALIDITY_VALUE_STATS', data.result)

      return data
    })
  },

  getGlobalStatData({ commit, state }) {
    return paymentService.getGlobalStat().then(({ data }) => {
      commit('SET_META_GLOBAL_STATS', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow
  getPaymentsList({ commit, state }, { page, field }) {
    if (state.metaPayment && state.metaPayment.current_page === page
      && state.base_amount_field === field.base_amount && state.paginate_field === field.paginate
      && state.svc_number_field === field.svc_number && state.road_id_field === field.road_id
      && state.payment_type_id_field === field.payment_type_id && state.reference_field === field.reference
      && state.op_amount_field === field.op_amount && state.destination_id_field === field.destination_id
      && state.transaction_id_field === field.transaction_id && state.amount_field === field.amount
      && state.user_id_field === field.user_id && state.op_api_id_field === field.op_api_id) return state.metaPayment

    return paymentService.getPayments(page, field).then(({ data }) => {
      commit('SET_META_PAYMENT', data.result/*.map((e) => {return {...e, cancelling: false, loading: false}})*/)
      commit('SET_BASE_AMOUNT_FIELD', field.base_amount)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_OP_API_ID_FIELD', field.op_api_id)
      commit('SET_AMOUNT_FIELD', field.amount)
      commit('SET_SVC_NUMBER_FIELD', field.svc_number)
      commit('SET_ROAD_ID_FIELD', field.road_id)
      commit('SET_PAYMENT_TYPE_ID_FIELD', field.payment_type_id)
      commit('SET_REFERENCE_FIELD', field.reference)
      commit('SET_OP_AMOUNT_FIELD', field.op_amount)
      commit('SET_DESTINATION_ID_FIELD', field.destination_id)
      commit('SET_FIELD', field)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_TRANSACTION_ID_FIELD', field.transaction_id)

      return data
    })
  },

  refeshPaymentsList({ commit, state }, { page, field }) {
    return paymentService.getPayments(page, field).then(({ data }) => {
      commit('SET_META_PAYMENT', data.result)
      commit('SET_BASE_AMOUNT_FIELD', field.base_amount)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_OP_API_ID_FIELD', field.op_api_id)
      commit('SET_AMOUNT_FIELD', field.amount)
      commit('SET_SVC_NUMBER_FIELD', field.svc_number)
      commit('SET_ROAD_ID_FIELD', field.road_id)
      commit('SET_PAYMENT_TYPE_ID_FIELD', field.payment_type_id)
      commit('SET_REFERENCE_FIELD', field.reference)
      commit('SET_OP_AMOUNT_FIELD', field.op_amount)
      commit('SET_DESTINATION_ID_FIELD', field.destination_id)
      commit('SET_FIELD', field)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_TRANSACTION_ID_FIELD', field.transaction_id)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getPaymentById({ commit, state }, id) {
    return paymentService.getPayment(id).then(({ data }) => {
      commit('SET_PAYMENT', data.result)

      return data
    })
  },
  verifyPaymentStatus({ commit, state }, payment) {
    return paymentService.verifyStatus(payment.id).then(({ data }) => {
      commit('UPDATE_PAYMENT', { payment: data.result, item: payment })

      return data
    })
  },
  cancelPayment({ commit }, payment) {
    return paymentService.cancelPayment(payment.id).then(({ data }) => {
     commit('UPDATE_PAYMENT', { payment: data.result, item: payment })
      return data
    })
  },
  // eslint-disable-next-line no-shadow
  newPaymentEvent({ commit, state }, id) {
    return paymentService.getPayment(id).then(({ data }) => {
      commit('ADD_PAYMENT', data.result)

      return data
    })
  },
}

const mutations = {
  SET_REFRESH_PAY_ID(state, item){
    state.refreshPayId = item
  },

  INCREMENT_REFRESH_PAY_COUNT(state){
    state.refreshPayCount++
  },

  // eslint-disable-next-line no-shadow
  SET_PAYMENT(state, item) {
    state.payment = item
  },

  // eslint-disable-next-line no-shadow
  UPDATE_PAYMENT(state, { payment, item }) {
    if (state.metaPayment) { state.metaPayment.data.splice(state.metaPayment.data.findIndex(t => t.id === item.id), 1, payment) }
  },

  // eslint-disable-next-line no-shadow
  ADD_PAYMENT(state, payment) {
    if (state.metaPayment) {
      if (state.metaPayment.per_page === state.field.paginate) state.metaPayment.data.pop()
      state.metaPayment.data.unshift(payment)
    } else {
      store.dispatch('payment/getPaymentsList', { page: 1, field: state.field }).catch(err => {
        console.log(err)
      })
    }
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_SVC_NUMBER_FIELD(state, item) {
    state.svc_number_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_ROAD_ID_FIELD(state, item) {
    state.road_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_TRANSACTION_ID_FIELD(state, item) {
    state.transaction_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_PAYMENT_TYPE_ID_FIELD(state, item) {
    state.payment_type_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_REFERENCE_FIELD(state, item) {
    state.reference_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_OP_AMOUNT_FIELD(state, item) {
    state.op_amount_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_DESTINATION_ID_FIELD(state, item) {
    state.destination_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_AMOUNT_FIELD(state, item) {
    state.amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },

  // eslint-disable-next-line no-shadow
  SET_OP_API_ID_FIELD(state, item) {
    state.op_api_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_BASE_AMOUNT_FIELD(state, item) {
    state.base_amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_META_PAYMENT(state, metaPayment) {
    state.metaPayment = metaPayment
  },

  SET_FILTER(state, item) {
    state.filterStat = item
  },

  SET_FILTER_WITHDRAW(state, item) {
    state.filterWithdraw = item
  },

  SET_FILTER_WITHDRAW_VALUE(state, item) {
    state.filterWithdrawValueStat = item
  },

  SET_FILTER_DEPOSIT_VALUE(state, item) {
    state.filterDepositValueStat = item
  },

  SET_FILTER_REASON_VALUE(state, item) {
    state.filterReason = item
  },

  SET_FILTER_VALIDITY_VALUE(state, item) {
    state.filterValidity = item
  },

  SET_META_STATS(state, item) {
    state.metaStats = item
    state.catX = []
    state.dataY = []
    Object.keys(item).forEach(key => {
      state.catX.push(key)
      state.dataY.push(item[key])
    })
  },

  SET_META_WITHDRAW_STATS(state, item) {
    state.metaWithdrawStats = item
    state.catWithdrawX = []
    state.dataWithdrawY = []
    Object.keys(item).forEach(key => {
      state.catWithdrawX.push(key)
      state.dataWithdrawY.push(item[key])
    })
  },

  SET_META_WITHDRAW_VALUE_STATS(state, item) {
    state.metaWithdrawValueStats = item
    state.catWithdrawValueX = []
    state.dataWithdrawValueY = []
    state.dataWithdrawValueColor = []
    state.dataWithdrawValuePercent = []
    Object.keys(item).forEach(key => {
      state.catWithdrawValueX.push(key)
      state.dataWithdrawValueY.push(item[key].value)
      state.dataWithdrawValueColor.push(item[key].color)
      state.dataWithdrawValuePercent.push(item[key].percent)
    })
  },

  SET_META_DEPOSIT_VALUE_STATS(state, item) {
    state.metaDepositValueStats = item
    state.catDepositValueX = []
    state.dataDepositValueY = []
    state.dataDepositValueColor = []
    state.dataDepositValuePercent = []
    Object.keys(item).forEach(key => {
      state.catDepositValueX.push(key)
      state.dataDepositValueY.push(item[key].value)
      state.dataDepositValueColor.push(item[key].color)
      state.dataDepositValuePercent.push(item[key].percent)
    })
  },

  SET_META_REASON_VALUE_STATS(state, item) {
    state.metaReasonStat = item
    state.catReasonValueX = []
    state.dataReasonValueY = []
    state.dataReasonValueColor = []
    state.dataReasonValuePercent = []
    Object.keys(item).forEach(key => {
      state.catReasonValueX.push(key)
      state.dataReasonValueY.push(item[key].value)
      state.dataReasonValueColor.push(item[key].color)
      state.dataReasonValuePercent.push(item[key].percent)
    })
  },

  SET_META_VALIDITY_VALUE_STATS(state, item) {
    state.metaValidityStat = item
    state.catValidityValueX = []
    state.dataValidityValueY = []
    state.dataValidityValueColor = []
    state.dataValidityValuePercent = []
    Object.keys(item).forEach(key => {
      state.catValidityValueX.push(key)
      state.dataValidityValueY.push(item[key].value)
      state.dataValidityValueColor.push(item[key].color)
      state.dataValidityValuePercent.push(item[key].percent)
    })
  },

  SET_META_GLOBAL_STATS(state, item) {
    state.metaGlobalStat = item
    state.globalStatKeys = []
    state.globalStatValue = []
    Object.keys(item).forEach(key => {
      state.globalStatKeys.push(key)
      state.globalStatValue.push(item[key])
    })
  },

  // eslint-disable-next-line no-shadow
  SET_META_OP_API(state, item) {
    state.metaOpApi = item
  },

  // eslint-disable-next-line no-shadow
  SET_META_PAYMENT_TYPE(state, item) {
    state.metaPaymentType = item
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
